@font-face {
    font-family: 'Gamer';
    src: url('Gamer.eot');
    src: url('Gamer.eot?#iefix') format('embedded-opentype'),
        url('Gamer.woff2') format('woff2'),
        url('Gamer.woff') format('woff'),
        url('Gamer.ttf') format('truetype'),
        url('Gamer.svg#Gamer') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

